import csx from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'

import Select from '../Select'
import TextInput from '../TextInput'

import debounce from '../../utils/debounce'
import t from '../../utils/translate'

import './autoComplete.styl'

export default function AutoComplete(props) {
  const {
    onSearch,
    style,
    defaultOptions,
    onSelect = () => {},
    value,
    translateLabel = false,
    withoutDebounce = false,
  } = props
  const searchInputRef = useRef(null)
  const [selectedValue, setSelectedValue] = useState(undefined)

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(defaultOptions || [])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setOptions(defaultOptions)
  }, [defaultOptions])

  useEffect(() => {
    setSelectedValue(value === '' ? undefined : value)
  }, [value])

  const updateOptions = async (updatedSearchValue, defaultOptions) => {
    let filteredOptions

    /* When the search value is empty we display simply the default options
     *
     * If a function is supplied we will use this function to search for available options.
     * The search function has to return a filtered list of options.
     *
     * If no function is supplied we perform a simple string search of all initial options.
     */
    if (updatedSearchValue === '') {
      filteredOptions = defaultOptions
    } else if (typeof onSearch === 'function') {
      filteredOptions = await onSearch(updatedSearchValue)
    } else {
      filteredOptions = defaultOptions.filter((option) => {
        const optionValue = option.value?.toString() || option.value
        const optionLabel = translateLabel ? t(option.label) : option.label

        return (
          optionValue
            ?.toLowerCase()
            ?.includes(updatedSearchValue.toLowerCase()) ||
          optionLabel.toLowerCase().includes(updatedSearchValue.toLowerCase())
        )
      })
    }

    setOptions(filteredOptions)
    setLoading(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(updateOptions, 500), [])

  const handleSearchChange = async (event) => {
    setLoading(true)
    const updatedValue = event.target.value

    setSearchValue(updatedValue)

    // If we perform the search inside the code instead of calling an API
    // there is not need for debouncing the search function.
    // This increases the performance for the user.
    if (typeof onSearch !== 'function' || withoutDebounce) {
      await updateOptions(updatedValue, defaultOptions)
    } else {
      debouncedSearch(updatedValue, defaultOptions)
    }
  }

  const handleSelectChange = (selected) => {
    setSelectedValue(selected)

    onSelect(selected)
  }

  const handleDropdownVisibleChange = (visible) => {
    if (visible) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 200)

      return
    }

    /* When the user closes the dropdown we clean up search.
     * Reset the search value and display the initial search options.
     */
    setSearchValue('')
    setOptions(defaultOptions)
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={style}
      className={csx('autocomplete-input', {
        'autocomplete-input--full-width': props.fullWidth,
      })}
    >
      <Select
        {...props}
        translateLabel={translateLabel}
        options={options}
        value={selectedValue}
        onChange={handleSelectChange}
        onSelect={() => {}}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        dropdownRender={(menu) => (
          <>
            <div className="autocomplete-input__search">
              <TextInput
                icon="magnifying-glass"
                ref={searchInputRef}
                value={searchValue}
                onChange={handleSearchChange}
                loading={loading}
              />
            </div>

            {menu}
          </>
        )}
      />
    </div>
  )
}
