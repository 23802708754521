import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Tooltip } from 'antd'
import { toJS } from 'mobx'
import { useHistory } from 'react-router-dom'

import ContentWrapper from '../../components/ContentWrapper'
import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import Text from '../../components/Text'
import Icon from '../../components/Icon'

import t from '../../utils/translate'
import { createRedirectPath } from '../../utils'
import { ActionLayerStore, RoleManagementStore, UIStore } from '../../stores'
import PageTitle from '../UserManagement/PageTitle'

const RoleManagementList = () => {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    RoleManagementStore.fetchRoles()
  }, [])

  const handleDelete = async () => {
    await RoleManagementStore.delete(selectedRows)

    if (RoleManagementStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleRowClick = (row) =>
    history.push(createRedirectPath('/role/' + row.id))

  const filters = [
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: RoleManagementStore.setFilter,
      defaultValue: RoleManagementStore.filter?.q,
      info: t('Please input at least three characters to search.'),
      placeholder: t('Search term'),
    },
  ]

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  // eslint-disable-next-line
  const checkDisable = (row) => row.name.includes('Makaira.')
  const headerActions = useMemo(() => {
    if (UIStore.features.includes('rbac')) {
      return {
        type: 'create',
        action: () => history.push(createRedirectPath('/role/new')),
        children: t('Add a role'),
      }
    }
    return {}
    // eslint-disable-next-line
  }, [UIStore.features, history])

  return (
    <>
      <PageTitle />
      <ContentWrapper className="role-management">
        <ResourceTable
          rowKey="id"
          title={t('Role')}
          store={RoleManagementStore}
          resourceName="roles"
          loading={RoleManagementStore.state === 'pending'}
          onRowClick={handleRowClick}
          headerAction={headerActions}
          noResultsText={t('No role could be found.')}
          emptyText={t('Role management')}
          createActionText={t('Add a role')}
          createAction={() => history.push(createRedirectPath('/role/new'))}
          filters={filters}
          rowSelection={{
            checkDisable,
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          bulkActions={bulkActions}
          hideEmptyComponentWhenEmpty={RoleManagementStore.hideEmptyComponent}
        >
          <Column
            title={t('Role')}
            dataIndex="name"
            render={(value) => {
              const roleName = value
                .replace('Makaira.', '')
                .replace(`${toJS(RoleManagementStore.rolePrefix)}.`, '')
              return (
                <Text
                  weight="bold"
                  key={`${value}-${RoleManagementStore.rolePrefix}`}
                >
                  {roleName}
                </Text>
              )
            }}
          />
          <Column
            width={150}
            title={t('Type')}
            align="center"
            dataIndex="name"
            render={(value) => (
              <Tooltip
                title={t(
                  !value.toLowerCase().includes('makaira.')
                    ? 'Custom role'
                    : 'Default role'
                )}
              >
                <Text>
                  <Icon
                    width="18px"
                    height="18px"
                    symbol={
                      !value.toLowerCase().includes('makaira.')
                        ? 'user-gear'
                        : 'gear'
                    }
                  />
                </Text>
              </Tooltip>
            )}
          />
          <Column
            width={50}
            render={() => <Icon symbol="angle-right" width={18} height={18} />}
          />
        </ResourceTable>
      </ContentWrapper>
    </>
  )
}

export default observer(RoleManagementList)
