import { observer } from 'mobx-react-lite'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import 'moment/locale/de'

import Text from '../Text'

import { UIStore } from '../../stores'

import t from '../../utils/translate'
import formatDate from '../../utils/formatDate'

function ChangedField(props) {
  const {
    changed: { date: dateString, timezone },
    user,
    withoutUser = false,
    tooltipTextOnly = false,
    timeOnly = false,
  } = props

  moment.locale(UIStore.currentLanguage)
  let formatted = moment.utc(dateString).local().fromNow()
  if (timezone !== 'UTC') {
    formatted = moment.tz(dateString, timezone).local().fromNow()
  }

  const detailed = formatDate({ dateString })
  let tooltip = `${detailed} `
  if (user !== undefined && !withoutUser) {
    tooltip += t('by') + ' ' + user
  }

  if (tooltipTextOnly) return tooltip

  if (timeOnly) return formatted

  return (
    <Tooltip title={tooltip}>
      <Text
        element="p"
        data-cy="table-changed"
        data-date={new Date(dateString)}
      >
        {formatted}
      </Text>
    </Tooltip>
  )
}

export default observer(ChangedField)
