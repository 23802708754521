import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import sortBy from 'lodash/sortBy'
import api from '../../api'
import formatLanguageKey from '../../utils/formatLanguageKey'
import availableLanguages from '../../utils/translate/languages'
import { createGallery, t } from '../../utils'
import AppStore from '../Apps/AppStore'
import ConfigurationStore from '../ConfigurationStore'
import ABTestStore from '../ABTestStore'

const browserLanguage = navigator.language || navigator.userLanguage

const cleanedBrowserLanguage =
  browserLanguage.length > 2 ? browserLanguage.slice(0, 2) : browserLanguage

const fallbackLanguage =
  availableLanguages && availableLanguages?.includes(cleanedBrowserLanguage)
    ? cleanedBrowserLanguage
    : 'en'

class UIStore {
  // languages of the shop, not of the admin ui
  languages = []
  // TODO: Save last used language in localStorage?
  // "locale" is fallback to selected language of old ui
  currentLanguage =
    localStorage.getItem('language') ||
    localStorage.getItem('locale') ||
    fallbackLanguage
  instances = []
  currentInstance = 'live'
  shopId = ''
  enterpriseConfiguration = {}
  documentTypes = []
  features = []
  featuresInfo = []
  instanceFetchStatusCode = 200
  availableLanguages = []
  setupConfiguration = {}
  externalApps = []
  isStorefrontPreview = false
  isBookingFeature = false
  isLoadingFeature = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *initialize() {
    yield Promise.all([
      this.fetchLanguages(),
      this.fetchShopId(),
      this.fetchEnterpriseConfiguration(),
      this.fetchFeatures(),
      this.fetchFeaturesInfo(),
      AppStore.fetchExternalApps(),
      ConfigurationStore.fetchConfiguration(),
      ABTestStore.fetchExperimentRunning(),
    ])
    createGallery()
  }

  *fetchInstances() {
    try {
      const { data } = yield api.common.getInstances()

      this.instances = data
      this.instanceFetchStatusCode = 200
    } catch (error) {
      // toast.error('Could not load instances.')
      try {
        this.instanceFetchStatusCode = JSON.parse(error.message).status
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  setInstance(instance) {
    this.currentInstance = instance
  }

  *fetchLanguages() {
    try {
      const { data } = yield api.common.getLanguages()

      this.languages = data
    } catch (error) {
      toast.error('Could not load languages.')
    }
  }

  setLanguage(instance) {
    this.currentLanguage = instance
    localStorage.setItem('language', instance)
    // set locale to also change the language in the old ui
    localStorage.setItem('locale', instance)
  }

  *fetchShopId() {
    try {
      const { data } = yield api.common.getShopId()

      this.shopId = data
    } catch (error) {
      toast.error('Could not load shop ID.')
    }
  }

  *fetchEnterpriseConfiguration() {
    try {
      const { data } = yield api.common.getEnterpriseConfiguration()

      this.enterpriseConfiguration = data
    } catch (error) {
      toast.error('Could not load storage configuration.')
    }
  }

  *fetchDocumentTypes() {
    try {
      const { data } = yield api.common.getDocumentTypes()

      this.documentTypes = data.map((type) => {
        return {
          id: type.id,
          title: type.title,
        }
      })
    } catch (error) {
      toast.error('Could not load storage configuration.')
    }
  }

  *fetchIndexStatus() {
    try {
      const { data } = yield api.common.updateIndexStatus()

      return {
        indices: data.indices.filter((index) => index.state === 'active'),
        replicatype: data.replicatype,
        loading: false,
      }
    } catch (error) {
      toast.error('Could not update index status.')
    }
  }

  get searchRedirectDocumentTypes() {
    const BLACKLISTED_TYPES = ['link', 'searchredirect']

    const types = this.documentTypes.filter(
      (type) => !BLACKLISTED_TYPES.includes(type.id)
    )

    types.push({ id: 'url', title: 'Manual URL' })

    return types
  }

  get languageOptions() {
    return this.languages.map((lang) => ({ value: lang, label: lang }))
  }

  get sortedLanguages() {
    const otherLanguages = this.languages.filter(
      (language) => language !== this.enterpriseConfiguration.defaultLanguage
    )
    return [
      this.enterpriseConfiguration.defaultLanguage,
      ...sortBy(otherLanguages, (language) => formatLanguageKey(language)),
    ]
  }

  *fetchFeatures() {
    try {
      const { data } = yield api.common.getFeatures()
      this.features = data
      this.state = 'done'
      return data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *fetchFeaturesInfo() {
    this.isLoadingFeature = true
    try {
      const { data } = yield api.common.getFeaturesInfo()
      this.featuresInfo = data
      this.state = 'done'
      this.isLoadingFeature = false
      return data
    } catch (error) {
      this.isLoadingFeature = false
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *fetchAvailableLanguages() {
    try {
      const { data } = yield api.common.getAvailableLanguages()
      this.availableLanguages = data
      this.state = 'done'
      return data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *fetchSetupConfiguration() {
    try {
      const { data } = yield api.installation.getSetup()
      this.setupConfiguration = data
      this.state = 'done'
      return data
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *fetchSearchResultPreview(
    searchPhrase,
    showEverything,
    indexState,
    language
  ) {
    const searchPhraseCleaned = searchPhrase.trim()

    if (searchPhraseCleaned === '') {
      return []
    }

    try {
      return yield api.common.fetchSearchResultPreview({
        searchPhrase: searchPhraseCleaned,
        shopId: this.shopId,
        datatypes: showEverything
          ? this.documentTypes.map((type) => type.id)
          : [],
        fields: [
          'id',
          'es_id',
          'ean',
          'title',
          '_score',
          'active',
          'searchable',
          'shop',
          'datatype',
          'hidden',
          'onstock',
          'picture_url_main',
          'hideOnLists',
        ],
        state: indexState,
        showEverything,
        language,
      })
    } catch (error) {
      this.state = 'error'
      toast.error('Could not fetch search result.')
      return []
    }
  }

  setStorefrontPreview(isPreview) {
    this.isStorefrontPreview = isPreview
  }

  *bookFeature(feature) {
    this.isBookingFeature = true
    try {
      yield api.common.bookFeature(feature)
      yield this.fetchFeaturesInfo()
      this.isBookingFeature = false
    } catch (error) {
      this.isBookingFeature = false
      this.state = 'error'
      toast.error(
        t('There was an error while starting your trial. Please try again.')
      )
    }
  }

  getFeatureInfo(featureName) {
    return this.featuresInfo.find((feat) => feat.name === featureName)
  }
}
// export "non-singleton" class to create new instances with custom configurations for unit test
export { UIStore as UIStoreClass }

export default new UIStore()
