import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'

class CategoryMappingStore {
  origin = {}
  mapping = {}
  tree = []
  showCategoryIds = false
  filter = null
  state = 'pending' // "pending", "done" or "error"
  isDirty = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  buildTree(tree, position = []) {
    // stops tree building when object is empty (initial store state is an empty object)
    if (tree === undefined) return []

    // stops recursion when tree is an empty array (e.g. parent node has no subtree)
    if (Array.isArray(tree) && tree.length === 0) return null

    return Object.values(tree).map((node) => {
      const newPosition = [...position]
      newPosition.push(node.key)

      return {
        title: node.title,
        key: node.key,
        google: node.google,
        position: newPosition,
        expanded: node.expanded,
        children: this.buildTree(node.subtree, newPosition),
      }
    })
  }

  reverseTree(builtTree, keepExpandState) {
    if (!Array.isArray(builtTree)) return {}

    let tree = {}

    builtTree.forEach((node) => {
      let subtree = this.reverseTree(node.children, keepExpandState)
      tree[node.key] = {
        title: node.title,
        key: node.key,
        google: node.google,
        subtree: subtree,
      }
      if (keepExpandState) {
        tree[node.key].expanded = node.expanded
        tree[node.key].position = node.position
      }
    })

    return tree
  }

  convertChildrenToSubtree(tree) {
    if (!Array.isArray(tree)) return tree

    return tree.map((node) => {
      const { children, expanded, position, ...rest } = node
      return {
        ...rest,
        subtree: this.convertChildrenToSubtree(children),
      }
    })
  }

  *fetchMapping() {
    this.state = 'pending'
    try {
      const { data } = yield api.categories.getMapping()

      this.mapping = data
      this.origin = data
      this.tree = this.buildTree(data)
      this.state = 'done'
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong loading the category mapping.')
      this.state = 'error'
    }
  }

  updateMapping(mapping) {
    this.mapping = mapping
    this.tree = this.buildTree(mapping)
    this.isDirty = true
  }

  updateTree(tree, setDirty = true) {
    this.tree = tree
    this.mapping = this.reverseTree(tree, true)
    if (setDirty) {
      this.isDirty = true
    }
  }

  *saveMapping() {
    this.state = 'pending'
    try {
      yield api.categories.updateMapping(
        this.convertChildrenToSubtree(this.tree)
      )

      this.state = 'done'
      this.isDirty = false
      toast.success('Category-Mapping saved!')
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong saving category mapping')
    }
  }

  setShowCategoryIds(show) {
    this.showCategoryIds = show
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  reset() {
    const originToJs = toJS(this.origin)
    this.mapping = originToJs
    this.tree = this.buildTree(originToJs)
    this.isDirty = false
  }
}

export default new CategoryMappingStore()
