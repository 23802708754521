import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

import AppItem from './AppItem'
import KPISection from './KPISection'
import { getApps } from './constants'
import AppDetailModal from './AppDetailModal'

import Text from '../../components/Text'
import LoadingScreen from '../../components/LoadingScreen'
import PrivateElement from '../../components/PrivateElement'
import OnboardingSection from './components/OnboardingSection'

import UIStore from '../../stores/UIStore'
import AppStore from '../../stores/Apps/AppStore'
import DashboardStore from '../../stores/DashboardStore'
import { AuthStore } from '../../stores'

import t from '../../utils/translate'

import './dashboard.styl'

function Dashboard() {
  useEffect(() => {
    DashboardStore.initialize()
    UIStore.fetchFeaturesInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isActiveOrTrialApp = (app) => {
    const appFeature = app.v1 ? app.requireFeature : app.route.requireFeature
    const featureInfo = UIStore.getFeatureInfo(appFeature)

    if (appFeature && !featureInfo) return false

    if (!featureInfo) return true

    return featureInfo.active
  }

  const externalApps = AppStore.externalApps.map((app) => ({
    name: get(app, ['title', UIStore.currentLanguage]),
    icon: get(app, 'icon'),
    route: { path: '/app/' + app.slug },
    external: true,
    slug: app.slug,
  }))

  const externalAppsByAlphabet = orderBy(externalApps, 'name', 'asc')
  const noAppSpecificPermission =
    AuthStore.permissions.includes('app.read') &&
    externalApps.every(
      (app) => !AuthStore.permissions.includes(app.slug + '.read')
    )

  const showAllApps =
    (!UIStore.features.includes('rbac') &&
      AuthStore.permissions.includes('app.read')) ||
    noAppSpecificPermission

  const getExternalAppsPermissions = (app) => {
    if (showAllApps) return null

    if (UIStore.features.includes('rbac'))
      return ['app.read', app.slug + '.read']

    return ['not.show']
  }

  const apps = getApps()

  const appsBySection = groupBy(apps, 'section')

  useEffect(() => {
    const hideEmptySection = () => {
      const appWrapperSelectors = document.querySelectorAll(
        '.dashboard__app-wrapper'
      )
      appWrapperSelectors.forEach((appWrapper) => {
        if (appWrapper.children.length === 0) {
          appWrapper.previousSibling.style.display = 'none'
          appWrapper.style.display = 'none'
        }
      })
    }
    setTimeout(hideEmptySection, 100)
  }, [externalAppsByAlphabet, appsBySection])

  if (UIStore.isLoadingFeature) return <LoadingScreen />

  return (
    <>
      <OnboardingSection />
      <div className="dashboard">
        <Text
          className="dashboard__heading"
          element="p"
          size="golf"
          weight="heavy"
        >
          {t('Welcome to Makaira!')}
        </Text>
        {UIStore.enterpriseConfiguration.environment !== 'stage' && (
          <PrivateElement permission={['settings.read']}>
            <KPISection />
          </PrivateElement>
        )}

        {Object.keys(appsBySection).map((section, index) => {
          // active/in trial apps > inactive (not yet booked) > external app
          const appsFiltered = [
            ...appsBySection[section].filter((app) => isActiveOrTrialApp(app)),
            ...appsBySection[section].filter((app) => !isActiveOrTrialApp(app)),
          ]
          return (
            <>
              <Text size="echo" weight="bold">
                {t(section)}
              </Text>
              <div className="dashboard__app-wrapper">
                {appsFiltered.map((app, idx) => (
                  <PrivateElement
                    permission={app.route?.permission || app.permission}
                    key={idx}
                  >
                    <AppItem app={app} />
                  </PrivateElement>
                ))}
              </div>
              {index === 0 && externalAppsByAlphabet.length > 0 && (
                <>
                  <Text size="echo" weight="bold">
                    {t('Apps')}
                  </Text>
                  <div className="dashboard__app-wrapper">
                    {externalAppsByAlphabet.map((app, idx) => (
                      <PrivateElement
                        key={idx}
                        permission={getExternalAppsPermissions(app)}
                      >
                        <AppItem app={app} />
                      </PrivateElement>
                    ))}
                  </div>
                </>
              )}
            </>
          )
        })}
      </div>
      <AppDetailModal />
    </>
  )
}

export default observer(Dashboard)
