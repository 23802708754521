import { makeAutoObservable, reaction, toJS } from 'mobx'
import { message } from 'antd'
import moment from 'moment'
import { cloneDeep, omit, range, set } from 'lodash'
import { toast } from 'react-toastify'

import api from '../../api'
import Text from '../../components/Text'
import { t } from '../../utils'

class ABTestStore {
  type = ''
  timeline = []
  upcoming = []
  done = []
  supportedMetrics = []
  running = null
  testDetail = null
  editingExperiment = {
    manualStart: true,
    startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    manualStop: false,
    stoppingThreshold: 90,
    stoppingMetric: '',
  }
  createStep = 1
  selectedMetrics = ''
  selectedVariant = 'A'
  showMetaconfigView = false
  showHighlightVariants = true
  originVariantConfig = {}

  rankingMixVariantsConfig = []
  personalizationVariantsConfig = []
  recommendationVariantsConfig = { recommendation_id: '', configs: [] }
  graph = {}
  table = []
  selectedTableMetric = null
  displayedVariants = []

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }

  sorter = {
    field: 'changed',
    order: 'desc',
  }
  filter = null
  state = 'pending' // "pending", "done" or "error"
  resultsState = 'pending'
  resultsError = null
  isFetchingSupportedMetrics = false
  isFetchingTest = false
  isCreatingTest = false
  isDirty = false
  isVariationsDirty = false
  isFiltered = false
  reactionVariantsConfig = null
  isDisableStartTest = false
  isFetchingVariantionConfig = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchTests()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchTests()
  }

  setFilter(filter) {
    this.reset()

    this.isFiltered = true

    this.filter = { ...this.filter, ...filter }
    if (this.type === 'timeline') {
      this.fetchTimelineTest()
    } else {
      this.fetchTests(this.type)
    }
  }

  *fetchTests(type, isInitialized) {
    type = type || this.type
    if (isInitialized) {
      this.filter = null
      this.isFiltered = false
    }

    // reset detail page data to initial value when going back to listing page
    this.graph = null
    this.table = null

    this.state = 'pending'
    this.type = type
    this[type] = []

    try {
      const { data, total } = yield api.abtests.getAll({
        pagination: this.pagination,
        filter: this.filter,
        params: {
          _state: type,
          _metric: this.selectedMetrics,
        },
      })
      this[type] = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      message.error(t('Something went wrong loading the ab tests listing.'))
      this.state = 'error'
    }
  }

  *fetchTimelineTest(isInitialized) {
    isInitialized = isInitialized || false

    this.state = 'pending'
    this.type = 'timeline'
    this.timeline = []
    this.reset()

    if (isInitialized) {
      this.filter = {
        _start: moment().subtract(4, 'years').toISOString(),
      }
    }

    try {
      const { data } = yield api.abtests.getTimelineTests({
        filter: this.filter,
      })
      this.timeline = data
      if (isInitialized) {
        const firstTest = data[0] || {}
        // start date filter should be the first test start date or 2 years from now
        if (moment(firstTest.startDate?.date).diff(moment(), 'year') > 2) {
          this.filter._start = firstTest.startDate?.date
        } else {
          this.filter._start = moment().subtract(2, 'years').toISOString()
        }
        this.filter._end = moment().add(90, 'days').toISOString()
      }
      this.state = 'done'
    } catch (error) {
      console.log(error)
      message.error(t('Something went wrong loading the ab tests listing.'))
      this.state = 'error'
    }
  }

  *fetchSupportedMetrics() {
    try {
      this.isFetchingSupportedMetrics = true
      const { data } = yield api.abtests.getSupportedMetrics()
      this.supportedMetrics = data.map((metric) => ({
        value: metric.name,
        label: t(`experiment_${metric.name}`),
      }))
      this.selectedMetrics = data[0]?.name
      this.selectedTableMetric = data[0]?.name
      if (!this.editingExperiment.stoppingMetric) {
        this.editingExperiment.stoppingMetric = data[0]?.name
      }
      this.isFetchingSupportedMetrics = false
    } catch (error) {
      this.isFetchingSupportedMetrics = false
      message.error(t('Something went wrong loading the supported metrics'))
    }
  }

  *fetchTestDetail(id) {
    this.isDirty = false
    this.graph = null
    this.table = null
    this.isDisableStartTest = false
    try {
      this.isFetchingTest = true
      const { data } = yield api.abtests.get(id)

      this.showMetaconfigView = true
      this.testDetail = data
      this.editingExperiment = data
      this.transformEditData(data)
      if (data.case !== 'frontend') {
        this.getVariationConfigs(id)
      }
      this.resetDisplayedVariants()

      yield this.fetchResultsIfRequired()

      this.isFetchingTest = false
    } catch (error) {
      if (error?.status === 404) {
        this.showMetaconfigView = false
      }
      console.error(error)
      this.isFetchingTest = false
      message.error(t('Something went wrong loading the A/B test'))
    }
  }

  *fetchResultsIfRequired() {
    if (!this.testDetail.running && !this.testDetail.finished) return

    this.resultsError = null
    this.resultsState = 'pending'
    this.graph = null

    try {
      const runningResult = yield api.experiments.results(this.testDetail.id)
      this.graph = runningResult.data.graph
      this.table = runningResult.data.table

      console.log(toJS(this.graph))

      this.resultsState = 'done'
    } catch (error) {
      console.log(error)
      this.resultsState = 'error'
      this.resultsError = JSON.parse(error.message).errorId ?? ''
    }
  }

  resetEditData() {
    const originData = cloneDeep(toJS(this.testDetail))
    this.editingExperiment = originData
    this.transformEditData(originData)
    this.isDirty = false
  }

  transformEditData(data) {
    // api not return A variant, so we need calculate and add it to variations
    const totalPercentageOfVariations = data.variations.reduce(
      (prev, current) => prev + current.trafficDistribution,
      0
    )
    this.editingExperiment.variations = [
      {
        name: 'A',
        trafficDistribution: 100 - totalPercentageOfVariations,
      },
      ...data.variations,
    ]
    this.editingExperiment.segments = this.editingExperiment.variations.length
    this.editingExperiment.startDate = moment(data.startDate?.date).format(
      'YYYY-MM-DD HH:mm:ss'
    )
    this.editingExperiment.stopDate = moment(data.stopDate?.date).format(
      'YYYY-MM-DD HH:mm:ss'
    )
  }

  setEditingExperiment(data) {
    this.editingExperiment = data
  }

  resetEditingExperiment() {
    this.editingExperiment = {
      manualStart: true,
      startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      manualStop: false,
      stoppingThreshold: 90,
    }
  }

  updateEditingExperiment(key, value) {
    this.editingExperiment[key] = value
    this.isDirty = true

    // reset segment when choose another case
    if (key === 'case') {
      delete this.editingExperiment.segments
    }
    // add default variations when choose segments
    if (key === 'segments') {
      let variations = [
        {
          name: 'A',
          trafficDistribution: 40,
          locked: false,
        },
        {
          name: 'B',
          trafficDistribution: 30,
          locked: false,
        },
        {
          name: 'C',
          trafficDistribution: 30,
          locked: false,
        },
      ]
      if (value === '2') {
        variations.pop()
        variations[1].trafficDistribution = 60
      }
      this.editingExperiment.variations = variations
    }
  }

  *updateOrCreate(stream) {
    const data = toJS(stream)
    // remove A variant from variations
    data.variations.shift()
    this.isCreatingTest = true
    try {
      if (stream.id) {
        yield api.experiments.update(data)
        this.isCreatingTest = false
        this.isDirty = false
      } else {
        const result = yield api.experiments.create(data)
        this.isCreatingTest = false
        this.isDirty = false
        this.resetVariantConfig()
        return result
      }
    } catch (error) {
      this.isCreatingTest = false
      this.state = 'error'
      try {
        const parseError = JSON.parse(error.message)
        if (parseError.status === 400) {
          toast.error(parseError.message)
        } else {
          toast.error('Something went wrong...')
        }
      } catch (err) {
        toast.error('Something went wrong...')
      }
      return false
    } finally {
      this.createStep = 1
    }
  }

  updateCreateStep(step) {
    this.createStep = step
  }

  toggleVariantLock(index, locked) {
    this.editingExperiment.variations[index].locked = locked
    const notLockedVariations = this.editingExperiment.variations.filter(
      (variant) => !variant.locked
    )

    if (notLockedVariations.length === 1) {
      if (locked) {
        // lock the last variant if only have one variant not locked
        notLockedVariations[0].locked = true
      } else {
        // unlock 2 variant at once when all the variants are locked
        const nextIndex = (index + 1) % this.editingExperiment.variations.length
        this.editingExperiment.variations[nextIndex].locked = false
      }
    }
  }

  updateVariantValue(index, value) {
    this.isDirty = true

    value = parseInt(value, 10)
    const currentVariant = this.editingExperiment.variations[index]
    let otherNotLockedVariation = []
    let otherLockedVariation = []

    this.editingExperiment.variations.forEach((variant) => {
      if (variant.name === currentVariant.name) return
      if (!variant.locked) {
        otherNotLockedVariation.push(variant)
      } else {
        otherLockedVariation.push(variant)
      }
    })

    const totalPercentageOfLockedVariations = otherLockedVariation.reduce(
      (prev, current) => prev + current.trafficDistribution,
      0
    )

    const totalPercentLeft = 100 - totalPercentageOfLockedVariations
    // other not locked variants should have at least 1%
    if (value + otherNotLockedVariation.length > totalPercentLeft || value < 1)
      return

    // update percent of current variant
    currentVariant.trafficDistribution = value

    // update percent for first other not locker variant
    // make sure all other not locked variant have at least 1%
    otherNotLockedVariation.forEach((variant, idx) => {
      if (idx === 0) {
        variant.trafficDistribution =
          totalPercentLeft - value - (otherNotLockedVariation.length - 1)
      } else {
        variant.trafficDistribution = 1
      }
    })
  }

  selectMetric(value) {
    this.selectedMetrics = value
    this.fetchTests()
  }

  *start(id) {
    try {
      yield api.experiments.start(id)
      message.success(t('Experiment started!'))
      this.fetchTestDetail(id)
      this.fetchExperimentRunning()
    } catch (error) {
      this.state = 'error'
      message.error(t(JSON.parse(error?.message || {}).message))
    }
  }

  *stop(id) {
    try {
      yield api.experiments.stop(id)
      message.success(t('Experiment stopped!'))
      this.fetchTestDetail(id)
      this.fetchExperimentRunning()
    } catch (error) {
      this.state = 'error'
      message.error('Failed to stop experiment.')
    }
  }

  get showResults() {
    return this.testDetail?.running || this.testDetail?.finished
  }

  *fetchExperimentRunning() {
    try {
      const { data } = yield api.experiments.running()

      this.running = data.experiments

      this.state = 'done'
    } catch (error) {
      this.running = null
      this.state = 'error'
      message.error('Something went wrong loading the experiment running.')
    }
  }

  *delete(ids) {
    try {
      yield api.experiments.delete(ids)
    } catch (error) {
      this.state = 'error'
      message.error('Something went wrong...')
    }

    this.fetchTests()
  }

  selectVariant(variant) {
    this.selectedVariant = variant
  }

  setShowHighlightVariants(show) {
    this.showHighlightVariants = show
  }

  *putVariationConfigs(config, id) {
    try {
      yield api.abtests.putVariationConfig(config, id)
      this.checkIsVariationsDirty()
      message.success(t('Versions updated!'))
    } catch (error) {
      message.error(t('Failed to update versions.'))
    }
  }

  transformRankingMixVariantConfig({ configs: data = [] }) {
    const variations = this.testDetail.variations

    variations.forEach((variation) => {
      const variantionData = data.find(
        (item) => item.variation_id === variation.id
      )

      if (!variantionData) {
        const newData = {}
        newData.variation_id = variation.id
        newData.variation_name = variation.name
        newData.settings = cloneDeep(data[0]?.settings)
        newData.settings[0] = omit(newData.settings[0], 'id')
        set(newData.settings[0], 'variation_id', variation.id)
        newData.settings[1] = omit(newData.settings[1], 'id')
        set(newData.settings[1], 'variation_id', variation.id)
        data.push(newData)
      } else {
        variantionData.variation_name = variation.name
      }
    })
    data[0].variation_name = 'A'

    return data
  }

  transformPersonalizationVariantConfig({ configs: data = [] }) {
    const newData = [...data]
    if (newData[0]) {
      newData[0].variation_name = 'A'
    }
    const variations = this.testDetail.variations
    variations.forEach((variation) => {
      const variationData = newData.find(
        (item) => item.variation_id === variation.id
      )
      if (!variationData) {
        newData.push({
          variation_id: variation.id,
          settings: {
            personalization: 'disabled',
          },
          variation_name: variation.name,
        })
      } else {
        variationData.variation_name = variation.name
      }
    })

    return newData
  }

  transformRecommendationVariantConfig(data) {
    if (data?.configs?.length === 0) {
      return { recommendation_id: '', configs: [] }
    }
    const newData = { ...data }

    newData.configs = newData.configs.map((config, index) => ({
      ...config,
      variation_name: ['A', 'B', 'C'][index],
    }))

    return newData
  }

  checkIsVariationsDirty() {
    if (this.reactionVariantsConfig) {
      // dispose if already exist
      this.reactionVariantsConfig()
    }

    this.isVariationsDirty = false

    this.reactionVariantsConfig = reaction(
      () => toJS(this.variationConfigByCase),
      () => {
        this.isVariationsDirty = true
      }
    )
  }

  resetVariantConfig() {
    this.originVariantConfig = {}
    this.rankingMixVariantsConfig = []
    this.personalizationVariantsConfig = []
    this.recommendationVariantsConfig = { recommendation_id: '', configs: [] }
  }

  undoVariantConfig() {
    const data = cloneDeep(toJS(this.originVariantConfig))

    switch (this.testDetail.case) {
      case 'ranking_mix':
        this.rankingMixVariantsConfig =
          this.transformRankingMixVariantConfig(data)
        break
      case 'personalization':
        this.personalizationVariantsConfig =
          this.transformPersonalizationVariantConfig(data)
        break
      default:
        break
    }

    this.checkIsVariationsDirty()
  }

  *getVariationConfigs(id) {
    this.isFetchingVariantionConfig = true
    try {
      const { data } = yield api.abtests.getVariationConfig(id)
      this.originVariantConfig = data

      switch (this.testDetail.case) {
        case 'ranking_mix':
          this.rankingMixVariantsConfig =
            this.transformRankingMixVariantConfig(data)
          break
        case 'personalization':
          this.personalizationVariantsConfig =
            this.transformPersonalizationVariantConfig(data)
          break
        case 'recommendation':
          this.recommendationVariantsConfig =
            this.transformRecommendationVariantConfig(data)
          break
        default:
          break
      }
      this.isFetchingVariantionConfig = false

      this.checkIsVariationsDirty()
    } catch (error) {
      this.isFetchingVariantionConfig = false
      console.log(error)
      message.error('Failed to get variation configs.')
    }
  }

  get variationConfigByCase() {
    switch (this.testDetail.case) {
      case 'ranking_mix':
        return this.rankingMixVariantsConfig
      case 'personalization':
        return this.personalizationVariantsConfig
      case 'recommendation':
        return this.recommendationVariantsConfig
      default:
        return null
    }
  }

  updateVariantConfig(variantData, key, value) {
    variantData[key] = value
    this.isVariationsDirty = true
  }
  get hasNotEnoughDataForGraph() {
    return this.graph?.bounce_count === 'There is no enough data'
  }

  get timelineWithoutDraft() {
    return this.timeline.filter((test) => test.status !== 'draft')
  }

  selectTableMetric(value) {
    this.selectedTableMetric = value
  }

  resetDisplayedVariants() {
    this.displayedVariants = range(this.editingExperiment.segments)
  }

  hideVariant(hiddenVariant) {
    this.displayedVariants = this.displayedVariants.filter(
      (variant) => variant !== hiddenVariant
    )
  }

  showVariant(shownVariant) {
    this.displayedVariants.push(shownVariant)
  }

  *applyVariation(variationId) {
    try {
      yield api.experiments.applyVariation(
        this.editingExperiment.id,
        variationId
      )

      const { data } = yield api.abtests.get(this.editingExperiment.id)

      this.testDetail = data
      this.editingExperiment = data
      this.transformEditData(data)
    } catch (error) {
      message.error(
        <span>
          {t('The selected variation could not be activated.')}
          <br />
          <Text size="alpha">
            Error-Code: {JSON.parse(error.message).errorId ?? ''}
          </Text>
        </span>
      )
    }
  }

  disableStartTest(isDisable) {
    this.isDisableStartTest = isDisable
  }

  setRecommendationVariantsConfig(data) {
    const variations = this.testDetail.variations

    this.recommendationVariantsConfig.recommendation_id = data.id
    const { id, ...newData } = data
    this.recommendationVariantsConfig = {
      recommendation_id: data.id,
      configs: variations.map((variation) => ({
        variation_id: variation.id,
        variation_name: variation.name,
        settings: newData,
      })),
    }
    this.recommendationVariantsConfig.configs.unshift({
      variation_name: 'A',
      variation_id: 0,
      settings: newData,
    })
  }

  setShowMetaconfigView(show) {
    this.showMetaconfigView = show
  }
}

export default new ABTestStore()
