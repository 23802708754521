import { observer } from 'mobx-react-lite'
import { UserManagementStore } from '../../../stores'
import { Popconfirm } from 'antd'
import HeadingMain from '../../../components/Headings/HeadingMain'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import { t } from '../../../utils'

function DeleteUser({ user }) {
  if (!user?.user_id) return null
  return (
    <div className="delete-user-section">
      <HeadingMain>{t('Remove access')}</HeadingMain>
      <Text>{t('The user will no longe be able to access this account.')}</Text>
      <Popconfirm
        title={t('Sure to delete?')}
        cancelText={t('Cancel')}
        onConfirm={() => UserManagementStore.delete([user.id])}
      >
        <Button variant="secondary-blocker" icon="trash" iconPosition="left">
          {t('Remove access')}
        </Button>
      </Popconfirm>
    </div>
  )
}

export default observer(DeleteUser)
