import { observer } from 'mobx-react-lite'
import React from 'react'
import ProductPreview from '../../../components/ProductPreview/ProductPreview'
import Text from '../../../components/Text'
import { StreamStore } from '../../../stores'
import { t } from '../../../utils'

const PreviewSection = (props) => {
  return (
    <div className="product-stream__preview-section">
      <Text size="echo" weight="bold">
        {t('Stream content')}
      </Text>
      <ProductPreview
        tagsInputShown={false}
        type={props.type ?? 'product'}
        hideSearchPharseInput
        hideSelectDocument
        singleRow
        typeSelectShown={false}
        onChange={props.onChange}
        products={StreamStore.previewList}
        loading={StreamStore.previewState === 'pending'}
        withoutSearchText={t('Select products to see the')}
        headline=""
        currentPage={StreamStore.previewPage + 1}
        maxPage={StreamStore.maxPreviewPage}
        maxProductsPerPage={StreamStore.MAX_PRODUCTS_IN_PREVIEW}
        previewLanguage={props.previewLanguage}
        onChangePreviewLanguage={props.onChangePreviewLanguage}
      />
    </div>
  )
}

export default observer(PreviewSection)
