import { arrayMove } from '@dnd-kit/sortable'
import { flatten, flattenDeep, get, isArray, set, sortBy } from 'lodash'
import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import api from '../../api'
import { delay, t } from '../../utils'
import {
  AVAILABLE_FIELD_TYPES,
  CONDITIONAL_SECTION_TYPE,
} from '../../views/ComponentEditor/constants'

class ComponentStore {
  components = []
  detail = {}
  flattenFields = [] // CRUD fields with flattenFields instead of detail.sections[].fields
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  searchComponent = '' // for filtering the components in Page Editor
  isFetchingList = false
  isFetchingDetail = false
  state = 'pending' // "pending", "done" or "error"
  isDirty = false
  elementUniqueIds = AVAILABLE_FIELD_TYPES.reduce((result, element, index) => {
    result[element.value] = uuidv4()
    if (index === AVAILABLE_FIELD_TYPES.length - 1) {
      result[CONDITIONAL_SECTION_TYPE.value] = uuidv4()
    }

    return result
  }, {})
  activeFieldId = ''
  sections = []
  uuids = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  usage = {
    state: 'pending',
    componentData: {},
    items: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    setComponentData: (data) => (this.usage.componentData = data),
    setPagination: (pagination) => {
      this.usage.pagination = pagination
      this.fetchUsage()
    },
    reset: () => {
      this.usage.pagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      this.usage.componentData = {}
      this.usage.items = []
    },
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  resetEditing() {
    this.detail = {}
    this.flattenFields = []
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchComponents()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchComponents()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchComponents()
  }

  getById(id) {
    return this.components.find((s) => s.id == id)
  }

  getByIdentifier(identifier) {
    return this.components.find((s) => s.identifier == identifier)
  }

  *fetchSections() {
    try {
      const { data } = yield api.components.getSections()
      this.sections = data.map((section) => ({ ...section, uuid: uuidv4() }))
    } catch (error) {
      toast.error('Something went wrong loading the section listing.')
    }
  }

  isNameUsed(name) {
    if (this.sections && this.sections.length > 0) {
      const filteredEntries = this.sections.filter(
        (section) => section.name.toLowerCase() === name.toLowerCase()
      )
      return filteredEntries.length > 0
    }
    return false
  }

  *saveSection(section, name) {
    const sectionWithAllChildFields = this.getFieldWithAllChildren(section)
    try {
      if (this.isNameUsed(name)) {
        toast.error(t('sectionExists')(name))
      } else {
        yield api.components.createSection({
          ...sectionWithAllChildFields,
          name,
        })
        toast.success(t('sectionSavedSuccess'))
        this.fetchSections()
      }
    } catch (error) {
      toast.error('Something went wrong saving the section.')
    }
  }

  *removeSection(sectionId) {
    try {
      yield api.components.deleteSection(sectionId)
      toast.success(t('Section deleted!'))
      this.fetchSections()
    } catch (error) {
      toast.error('Something went wrong deleting the section.')
    }
  }

  *fetchComponents(fetchAll) {
    fetchAll = fetchAll || false
    this.isFetchingList = true
    try {
      let options = fetchAll
        ? {}
        : {
            pagination: this.pagination,
            filter: this.filter,
            sorter: this.sorter,
          }

      const { data, total } = yield api.components.getAll(options)

      this.components = data
      this.pagination.total = total
      this.state = 'done'
      this.isFetchingList = false
    } catch (error) {
      toast.error('Something went wrong loading the component listing.')
      this.state = 'error'
      this.isFetchingList = false
    }
  }

  *fetchUsage() {
    this.usage.state = 'pending'
    try {
      const { data, total } = yield api.components.getUsage({
        pagination: this.usage.pagination,
        id: this.usage.componentData.id,
      })

      this.usage.items = data
      this.usage.pagination.total = total || data.length
      this.usage.state = 'done'
    } catch (error) {
      toast.error('Fetching component usage failed.')
      this.usage.state = 'error'
    }
  }

  getAllFields(originalField, index) {
    // We need to create a deep copy of the field here because otherwise the below code
    // would ACCIDENTALLY mutate its data structure, e.g. removing childConfigs etc.
    const field = JSON.parse(JSON.stringify(originalField))

    if (field.type === 'section' || !field.type) {
      const section = {
        ...field,
        type: 'section',
        uuid: field.uuid || uuidv4(),
        position: index,
      }

      const fields =
        section.fields?.map((childField) => ({
          ...childField,
          sectionId: section.uuid,
          parentId: section.uuid,
        })) || []

      return [section, ...fields.map(this.getAllFields)]
    }

    // update position so we can use in sortable
    field.position = index

    // some real case field not have uuid yet
    if (!field.uuid || this.uuids.includes(field.uuid)) {
      field.uuid = uuidv4()
    }

    this.uuids.push(field.uuid)

    if (
      field.type !== 'array' &&
      field.type !== 'object' &&
      field.type !== 'hotspot'
    ) {
      return [field]
    }

    const propertiesKey =
      field.type === 'array' ? 'childConfig.properties' : 'properties'

    // get all child fields
    // add additional data: parentId, level -> use to handle drag drop, use to build the fields later
    const properties = [...get(field, propertiesKey, [])].map((childField) => ({
      ...childField,
      parentId: field.uuid,
    }))

    // it a flatten field so we need remove all child fields
    set(field, propertiesKey, undefined)

    return [field, ...properties.map(this.getAllFields)]
  }

  handleConditionalSection(fields) {
    let newFields = []
    let addedConditionalSectionUuids = []

    fields.forEach((field) => {
      if (
        field.conditionField &&
        !addedConditionalSectionUuids.includes(field.conditionalSectionId)
      ) {
        newFields.push({
          type: 'conditional-section',
          uuid: field.conditionalSectionId,
          position: field.position,
          conditionField: field.conditionField,
          conditionValue: field.conditionValue,
          showFields: field.showFields,
          parentId: field.parentId,
        })
        addedConditionalSectionUuids.push(field.conditionalSectionId)
      }
      field.parentId = field.conditionalSectionId || field.parentId
      newFields.push(field)
    })

    return newFields
  }

  *fetchComponentDetail(id) {
    this.isFetchingDetail = true
    this.detail = {}
    this.flattenFields = []

    if (id === 'new') {
      yield delay(100)
      this.detail = {}
      this.isFetchingDetail = false
      return
    }

    try {
      const { data } = yield api.components.get(id)
      this.detail = data
      const allFields = flattenDeep(data.sections?.map(this.getAllFields) || [])
      this.flattenFields = this.handleConditionalSection(allFields)
      this.isFetchingDetail = false
      this.state = 'done'
    } catch (error) {
      this.isFetchingDetail = false
      this.state = 'error'
      toast.error('Something went wrong fetching component detail...')
    }
  }

  async updateOrCreate() {
    try {
      if (this.detail.id) {
        const { data } = await api.components.update({
          ...this.detail,
          sections: this.getFieldsTree(),
        })
        return data
      } else {
        const { data } = await api.components.create({
          ...this.detail,
          sections: this.getFieldsTree(),
        })
        return data
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(id) {
    try {
      yield api.components.delete(id)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchComponents()
  }

  setSearchPhrase(searchPhrase = '') {
    this.searchComponent = searchPhrase.toLowerCase().trim()
  }

  get filtedComponents() {
    return this.components.filter(
      (component) =>
        component.name.toLowerCase().includes(this.searchComponent) &&
        !component.deprecated
    )
  }

  updateDetail(key, value) {
    this.isDirty = true
    this.detail[key] = value
  }

  createUniqueIdForField(field) {
    const similarIds = this.flattenFields
      .filter((f) => f.id?.includes(field.id))
      .map((f) => f.id)

    let id = 1
    while (similarIds.includes(field.id + '-' + id) && id < 1000) {
      id = id + 1
    }

    return field.id + '-' + id
  }

  addField(field) {
    if (field.type !== 'conditional-section' && field.type !== 'section') {
      field.id = this.createUniqueIdForField(field)
    }
    this.isDirty = true
    this.flattenFields.push(field)
    // create new uuid for form element
    this.elementUniqueIds[field.type] = uuidv4()
    this.setActiveField(field.uuid)
  }

  removeElement(field) {
    this.isDirty = true

    this.flattenFields = this.flattenFields.filter((f) => f.uuid !== field.uuid)

    // also remove all child elements
    const allChildFields = this.getFieldWithAllChildren(field)
    let flattenAllChildFields = flattenDeep(
      (isArray(allChildFields) ? allChildFields : [allChildFields]).map(
        this.getAllFields
      ) || []
    )
    // flattenAllChildFields not include 'conditional-section' and 'section' field
    if (field.type === 'conditional-section' || field.type === 'section') {
      flattenAllChildFields.push(field)
    }
    flattenAllChildFields.forEach(
      (fieldNeedRemove) =>
        (this.flattenFields = this.flattenFields.filter(
          (f) => f.uuid !== fieldNeedRemove.uuid
        ))
    )
    this.updateChildsPosition(field.parentId)
  }

  updateField(uuid, key, value) {
    this.isDirty = true
    const field = this.getFieldByUUID(uuid)
    if (field) {
      set(field, key, value)
    }
  }

  getFieldByUUID(uuid) {
    return this.flattenFields.find((f) => f.uuid === uuid)
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  // function to build the detail.fields from flattenFields
  getFieldsTree(withoutSection = false) {
    const rootFields = sortBy(
      this.flattenFields.filter((f) => f.type === 'section'),
      'position'
    )

    let fieldsTree = flatten(rootFields.map(this.getFieldWithAllChildren))

    //remove empty section
    fieldsTree = fieldsTree.filter((f) => f?.fields?.length > 0)

    if (withoutSection) {
      let fieldsTreeWithoutSection = []
      fieldsTree.forEach((section) =>
        fieldsTreeWithoutSection.push(...section.fields)
      )

      return fieldsTreeWithoutSection
    }

    return fieldsTree
  }

  getFieldWithAllChildren(field) {
    if (
      field.type !== 'object' &&
      field.type !== 'array' &&
      field.type !== 'hotspot' &&
      field.type !== 'section' &&
      field.type !== 'conditional-section'
    )
      return field

    const properties = sortBy(
      this.flattenFields.filter((f) => f.parentId === field.uuid),
      'position'
    )

    if (field.type === 'conditional-section')
      return flatten(properties.map(this.getFieldWithAllChildren)).map((f) => ({
        ...field,
        ...f,
        parentPosition: field.position,
        conditionalSectionId: field.uuid,
        conditionField: field.conditionField,
        conditionValue: field.conditionValue,
      }))

    if (field.type === 'object' || field.type === 'hotspot') {
      return {
        ...field,
        properties: flatten(properties.map(this.getFieldWithAllChildren)),
      }
    }
    if (field.type === 'array') {
      return {
        ...field,
        childConfig: {
          ...(field.childConfig || {}),
          properties: flatten(properties.map(this.getFieldWithAllChildren)),
        },
      }
    }
    if (field.type === 'section') {
      return {
        ...field,
        fields: flatten(properties.map(this.getFieldWithAllChildren)),
      }
    }
  }

  handleAddSectionToPlaceholder(active) {
    const dropableId = uuidv4()
    const newPosition = this.flattenFields.filter(
      (f) => f.type === 'section'
    ).length
    const newSection = {
      type: 'section',
      uuid: dropableId,
      position: newPosition,
    }
    let newFields = []
    if (active?.data?.current?.type === 'section') {
      const sectionInfo = this.sections.find(
        (section) => section.id === active?.data?.current?.sectionId
      )
      newSection.uuid = sectionInfo.uuid
      const rootFields = toJS(sectionInfo?.fields || [])
      const rootFieldsUUID = rootFields.map((f) => f.uuid)
      let flattenFields = flattenDeep(rootFields.map(this.getAllFields) || [])
      let conditionalUUIDUpdated = {} // {[<old-uuid>]: <new-uuid>}

      // assign new uuid for every field
      flattenFields.forEach((field) => {
        const oldUUID = field.uuid
        const newUUID = uuidv4()
        field.uuid = newUUID
        if (rootFieldsUUID.includes(oldUUID)) {
          // assign parentId to new section if it the root field
          field.parentId = sectionInfo.uuid
        }
        // assign new uuid for conditional-section
        if (field.conditionalSectionId) {
          if (!conditionalUUIDUpdated[field.conditionalSectionId]) {
            const newConditionalSectionId = uuidv4()
            conditionalUUIDUpdated[field.conditionalSectionId] =
              newConditionalSectionId
            field.conditionalSectionId = newConditionalSectionId
          } else
            field.conditionalSectionId =
              conditionalUUIDUpdated[field.conditionalSectionId]
        }

        // also update parentId and conditionField
        flattenFields.forEach((f) => {
          if (f.parentId === oldUUID) f.parentId = newUUID
          if (f.conditionField === oldUUID) f.conditionField = newUUID
        })
      })

      // assign section data
      newSection.label = sectionInfo.label
      newSection.description = sectionInfo.description
      // create new uuid for section
      sectionInfo.uuid = uuidv4()

      // get conditional-section fields
      flattenFields = this.handleConditionalSection(flattenFields)

      // assign fields updated above
      newFields = flattenFields
    }

    this.addField(newSection)

    if (newFields.length > 0) {
      newFields.forEach((f) => this.addField(f))
    }

    return newSection.uuid
  }

  handleDragElementToDropable(active, overId) {
    if (!overId.startsWith('dropable')) return

    let activeField = this.getFieldByUUID(active?.id)
    // overId template: 'dropable--<uuid>--position
    // overId example: 'dropable--a54dcb5f-6a55-4de7-9ac8-114c69c16b49--2
    let [dropableId, position] = overId.replace('dropable--', '').split('--')

    dropableId = dropableId || undefined
    position = parseInt(position)

    // create new section if dropped to placeholder section
    if (dropableId === 'placeholder') {
      dropableId = this.handleAddSectionToPlaceholder(active)
    }

    if (active?.data?.current?.type === 'section') return

    if (active?.data?.current?.type === 'form-section') {
      position = this.getFieldByUUID(dropableId)?.position
      dropableId = undefined
    }

    // add field to the flattenFields if it not available yet
    // then get the active field again
    if (!activeField) {
      this.addField(active?.data?.current?.field)
      activeField = this.getFieldByUUID(active?.id)
    }

    // get all the fields with the parentId = dropableId
    let siblingFields = sortBy(
      this.flattenFields.filter((field) => field.parentId === dropableId),
      'position'
    )
    const oldParentId = activeField.parentId

    if (
      activeField.position === undefined ||
      activeField.parentId !== dropableId
    ) {
      this.updateField(active.id, 'position', siblingFields.length)
      this.updateField(active.id, 'parentId', dropableId)
      siblingFields = sortBy(
        this.flattenFields.filter((field) => field.parentId === dropableId),
        'position'
      )
    }

    if (oldParentId !== activeField.parentId) {
      this.updateChildsPosition(oldParentId)
    }

    if (position >= siblingFields.length) {
      position = siblingFields.length - 1
    }

    if (parseInt(activeField?.position) !== position) {
      const newArray = arrayMove(
        siblingFields,
        parseInt(activeField?.position),
        parseInt(position)
      )
      newArray.forEach((field, index) =>
        this.updateField(field.uuid, 'position', index)
      )
    }
  }

  updateChildsPosition(parentId) {
    const siblingFieldsOfParent = sortBy(
      this.flattenFields.filter((field) => field.parentId === parentId),
      'position'
    )
    siblingFieldsOfParent.forEach((field, index) =>
      this.updateField(field.uuid, 'position', index)
    )
  }

  setActiveField(uuid) {
    this.activeFieldId = uuid
  }

  get activeConfiguration() {
    return this.getFieldByUUID(this.activeFieldId)
  }

  importStructure(structure) {
    try {
      const sections = JSON.parse(structure)
      this.detail.sections = sections
      const allFields = flattenDeep(sections.map(this.getAllFields) || [])
      this.flattenFields = this.handleConditionalSection(allFields)
      this.isDirty = true
    } catch (error) {
      toast.error('JSON not valid')
      console.log(error)
    }
  }
}

export default new ComponentStore()
